/* Custom CSS not realted to Feeling Responsive */

/* Layout */
body {
    display: flex;
    flex-direction: column;
}

.row.main-content {
    flex: 1 0 auto;
}

/* Footer */
#footer-content {
    border-top: 1px solid lighten($primary-color-1, 95%);
}
#footer-content #subfooter #subfooter-left {
    display: flex;

}
#footer-content #subfooter #subfooter-left p {
    font-size: .75rem;
    line-height: 1rem;
    color: $primary-color-1;
    margin-bottom: 0;
}

#footer-content #subfooter #subfooter-left .subfooter-left__icons {
    display: flex;
    flex-shrink: 0;

    img {
        margin-right: .25rem;
    }
}

#footer-content #subfooter #subfooter-left .subfooter-left__text {
    display: inline-block; 
    padding-left: .5rem;     
}

#subfooter {
    width: 100%;
    max-width: 62.5rem;
    margin: 0 auto;
}

#subfooter #subfooter__copyright p {
    color: $primary-color-1;
    font-size: .75rem;
    margin-bottom: 0;
}

#subfooter .subfooter__logo {
    text-align: center;
    margin-bottom: 1rem;
}

#subfooter .subfooter__logo img {
    width: 4rem;
}

#subfooter .subfooter__links {
    padding-left: 1rem;
    padding-right: 1rem;
    @media only screen and (min-width: 641px) {
        padding-left: 0;
        padding-right: 0;
    }
}

#subfooter .credits {
    text-align: left;
    padding-bottom: .5rem;
    &.credits--imprint {
        padding-bottom: 1.5rem;
        text-align: center;
    }
}

@media only screen and (min-width: 640px)  {
    #footer-content {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    #footer-content #subfooter #subfooter-left p {
        line-height: 27px;
    }
    #subfooter {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    #subfooter .subfooter__logo img {
        width: 4.5rem;
    }
    #subfooter .credits {
        text-align: left;
        padding-bottom: .5rem;
        padding-right: 2rem;
        &.credits--imprint {
            padding-bottom: 2rem;
            text-align: left;
        }
    }
}

@media only screen and (min-width: 1024px) {
    #subfooter .subfooter__logo {
        text-align: right;
        padding-right: 2rem;
    }
    #subfooter .subfooter__logo img {
        width: 6rem;
    }
}

.top-bar__logo {
    height: 2rem;
    transform: translateY(-2px);
}

// Imprint
.imprint__wrap {
    padding-left: 1rem;
    padding-right: 1rem;
}

.imprint__heading {
    font-size: 1.25rem;
    font-weight: 700;
    color: #222;
    display: block;
}

.imprint__content {
    padding-top: 1rem;

    @media only screen and (min-width: 1024px) {
        padding-top: 2rem;
    }
}

.imprint__small {
    font-size: .85rem;
    padding-top: 1.5rem;

    @media only screen and (min-width: 1024px) {
        font-size: 1rem;
        padding-top: 2rem;
    }
}
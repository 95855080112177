.header__wrap {
  width: 100%;
  max-width: 62.5rem;
  margin: 0 auto;

  h1 {
    font-size: 1.65rem;
    text-align: center;

    @media only screen and (min-width: 641px) {
      font-size: 1.85rem;
      padding-top: 1rem;
    }

    @media only screen and (min-width: 1025px) {
      font-size: 2rem;
      padding-top: 1rem;
    }
  }
}
$partner-card-helper-height: 13rem;
$partner-card-margin-desktop: .75rem;

.partners {
  max-width: 62.5rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding-left: 1rem;
  padding-right: 1rem;

  @media only screen and (min-width: 641px) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  a {
    color: #1F567D;
  }


  .partners__text {
    padding-top: 1rem;
    margin-bottom: 1.25rem;
    text-align: left;
    line-height: 1.75rem;
    width: 100%;
    text-align: center;

    @media only screen and (min-width: 1025px) {
      padding-top: 0;
      margin-top: 4rem;
      font-size: 1.15rem;
    }
  }

  .partners__item {
    box-shadow: 0px 4px 16px rgba(31,86,125,0.1);
    border-radius: 8px;
    width: 100%;
    display: block;
    position: relative;
    padding: 0 .75rem 1.5rem;
    margin: $partner-card-margin-desktop 0;

    @media only screen and (min-width: 1025px) {
      margin: $partner-card-margin-desktop $partner-card-margin-desktop;
      width: calc(50% - (#{$partner-card-margin-desktop} * 2));
      padding: 0 1.25rem 2rem;
    }
  }

  @media only screen and (min-width: 1025px) {
    > .partners__item:nth-child(odd) {
      margin-left: $partner-card-margin-desktop;
      margin-right: $partner-card-margin-desktop;
    }

    > .partners__item:nth-child(even) {
      margin-left: $partner-card-margin-desktop;
      margin-right: $partner-card-margin-desktop;
    }
  }

  h2.partners__name {
    font-size: 1.25rem;
  }

  .partners__upper--helper {
    display: none;
  }

  @media only screen and (min-width: 1025px) {
    .partners__upper--helper {
      display: block;
      height: $partner-card-helper-height;
    }
  
    .partners__upper {
      height: $partner-card-helper-height;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      top: 0;
      text-align: left;
    }
  }
}

.partners__logo {

  @media only screen and (min-width: 1025px) {
    display: flex;
    flex: 1;
    align-items: center;
  }

  img {
    height: 7rem;
    display: inline;
    padding-top: 1rem;

    @media only screen and (min-width: 1025px) {
      padding-top: 0;
    }
  }
}